*{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}
button{
  padding: 5px;
}
.App{
  width:100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form{
  width: 50%;
  gap:5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.rezultat{
  display:flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
  gap:10px;
}
